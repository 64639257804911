import { Injectable } from '@angular/core';
import { UniHttp } from '@uni-framework/core/http/http';
import { Comment } from '@app/unientities';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';
import { StatisticsService } from './statisticsService';

@Injectable({ providedIn: 'root' })
export class CommentService {
    commentAdded$ = new Subject<Comment>();

    constructor(
        protected http: UniHttp,
        private statisticsService: StatisticsService,
    ) {}

    getAll(entity: string, entityID: number, companyKey?: string, allowTags = false) {
        const route = `comments/${entity}/${entityID}`;
        const args = ['expand=Author'];
        if (allowTags) args.push('allowtags=true');
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`${route}?${args.join('&')}`)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .send({}, null, companyKey ? false : true)
            .pipe(map((res) => res.body || []));
    }

    getCommentCount(entity: string, entityID: number) {
        const odata =
            `model=Comment` +
            `&select=count(ID) as Count` +
            `&filter=EntityType eq '${entity}' and EntityID eq ${entityID}`;

        return this.statisticsService.GetAllUnwrapped(odata).pipe(
            map((res) => res && res[0] && res[0].Count),
            catchError((err) => {
                console.error(err);
                return of(0);
            }),
        );
    }

    saveAll(
        list: Array<{ ID?: number; Text: string; Deleted?: boolean }>,
        entity: string,
        entityID: number,
        companyKey?: string,
    ) {
        const requests = [];

        // Updates
        requests.push(
            ...list.filter((p) => p.ID && !p.Deleted).map((item) => this.put(item.ID, item.Text, companyKey)),
        );

        // New entries
        requests.push(...list.filter((p) => !p.ID).map((item) => this.post(entity, entityID, item.Text, companyKey)));

        // Deletes
        requests.push(...list.filter((p) => p.ID && p.Deleted).map((item) => this.delete(item.ID)));

        if (requests.length) {
            return concat(...requests).pipe(
                concatMap((res) => {
                    return of(res);
                }),
            );
        }

        return of([]);
    }

    delete(id: number) {
        return this.http
            .asDELETE()
            .usingBusinessDomain()
            .withEndPoint(`${Comment.RelativeUrl}/${id}`)
            .send({}, undefined, true)
            .pipe(map((res) => res.body));
    }

    put(id: number, message: string, companyKey?: string) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`${Comment.RelativeUrl}/${id}`)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .withBody({ ID: id, Text: message })
            .send({}, undefined, !companyKey)
            .pipe(map((res) => res.body));
    }

    post(entity: string, entityID: number, message: string, companyKey?: string) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${Comment.RelativeUrl}/${entity}/${entityID}`)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .withBody({ Text: message })
            .send({}, null, companyKey ? false : true)
            .pipe(
                map((res) => res.body as Comment),
                tap((res) => res && this.commentAdded$.next(res)),
            );
    }

    generate(prompt: string, temperature = 0, topPercentage = 99): Observable<Comment> {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${Comment.RelativeUrl}?action=generate`)
            .withBody({ Prompt: prompt, Temperature: temperature, TopPercentage: topPercentage })
            .send({}, null, true)
            .pipe(
                map((res) => {
                    const result = res.body as Comment;
                    if (result) result.Text = this.cleanupResponse(result.Text);
                    return result;
                }),
            );
    }

    private cleanupResponse(text: string): string {
        if (!text) return text;
        if (text.startsWith('.')) {
            text = text.substring(1);
        }
        if (text.startsWith('\n\n')) {
            text = text.substring(2);
        }
        if (text.startsWith('"') && text.endsWith('"')) {
            text = text.substring(1, text.length - 2);
        }
        return text.trim();
    }
}
