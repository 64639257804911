import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { WizardStep } from '../wizard';

@Component({
    selector: 'wizard-tabs',
    templateUrl: './wizard-tabs.html',
    styleUrls: ['./wizard-tabs.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RigWizardTabs {
    @Input() tabs: WizardStep[];
    @Input() activeIndex: number;
    @Output() activeIndexChange = new EventEmitter<number>();

    constructor(
        private cdr: ChangeDetectorRef,
        private elementRef: ElementRef<HTMLElement>,
    ) {}

    activateTab(index: number) {
        this.activeIndex = index;
        this.activeIndexChange.emit(index);
        this.cdr.markForCheck();
    }
    onKeydown(event: KeyboardEvent) {
        if (event.key == 'ArrowUp') {
            event.preventDefault();

            let nextIndex = this.activeIndex - 1;
            if (nextIndex < 0) {
                nextIndex = this.tabs.length - 1;
            }

            this.activateTab(nextIndex);
            this.focusTab(nextIndex);
        } else if (event.key == 'ArrowDown') {
            event.preventDefault();

            let nextIndex = this.activeIndex + 1;
            if (nextIndex >= this.tabs.length) {
                nextIndex = 0;
            }

            this.activateTab(nextIndex);
            this.focusTab(nextIndex);
        }
    }

    private focusTab(index: number) {
        const tabs = this.elementRef?.nativeElement?.getElementsByTagName('button');
        tabs?.item(index)?.focus();
    }
}
