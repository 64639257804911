import { Injectable } from '@angular/core';
import { UniHttp } from '../../../framework/core/http/http';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { ReportDefinitionParameter } from '../../unientities';

export interface InputParam {
    Name: string;
    Label: string;
    pairedWith?: { input: InputParam; isLeftPair: boolean };
}

@Injectable({ providedIn: 'root' })
export class ReportDefinitionParameterService extends BizHttp<ReportDefinitionParameter> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = ReportDefinitionParameter.RelativeUrl;
        this.entityType = ReportDefinitionParameter.EntityType;
        this.DefaultOrderBy = 'SortIndex,ID';
    }

    findPairs(inputs: InputParam[]): InputParam[] {
        const pairs = [];
        for (var i = 0; i < inputs.length; i++) {
            const current = inputs[i];
            for (var x = 1; x < inputs.length; x++) {
                if (x === i) continue;
                if (current.pairedWith || inputs[x].pairedWith) continue;
                if (this.isInputPair(current, inputs[x])) {
                    current.pairedWith = { input: inputs[x], isLeftPair: true };
                    inputs[x].pairedWith = { input: current, isLeftPair: false };
                    pairs.push(current);
                }
            }
        }
        return pairs;
    }

    isInputPair(f1: InputParam, f2: InputParam): boolean {
        var propertyChecks = [
            { property: 'Label', part1: 'Fra ', part2: 'Til ' },
            { property: 'Name', part1: 'from', part2: 'to' },
            { property: 'Name', part1: 'f', part2: 't' },
        ];

        for (var i = 0; i < propertyChecks.length; i++) {
            const check = propertyChecks[i];
            const p1 = f1[check.property];
            const p2 = f2[check.property];
            if (p1 && p2) {
                if (p1.startsWith(check.part1) && p2.startsWith(check.part2)) {
                    if (p1.substring(check.part1.length) === p2.substring(check.part2.length)) {
                        return true;
                    }
                }
            }
        }

        return false;
    }
}
