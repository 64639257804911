import { Component, Input } from '@angular/core';

@Component({
    selector: 'cms-illustration',
    template: `<uni-illustration [name]="item.illustration"></uni-illustration>`,
    styleUrls: ['./illustration.sass'],
})
export class CMSIllustration {
    @Input() item: any;
}
