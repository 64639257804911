<file-input
    *ngIf="!hideUploadInput && !readonly"
    [layout]="files?.length > 0 ? 'button-only' : 'small'"
    [entityType]="entity"
    [entityID]="entityID"
    [multiple]="!singleImage"
    [canUseInbox]="canUseInbox"
    [accept]="accept"
    (filesUploaded)="onFilesUploaded($event)"
>
</file-input>

<article class="uniImage" tabindex="-1" (keydown)="onKeyDown($event)" (dragover)="onDragover($event)">
    <section
        class="file-drop-zone flex-column align-center justify-center"
        *ngIf="dragAndDropInProgress"
        (dragleave)="dragAndDropInProgress = false"
        (drop)="onDrop($event)"
    >
        <svg fill="none" width="35" viewBox="0 0 25 25">
            <path
                d="m16.012 13.905 6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15-.036-8.063Zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3h-10v10h4v2h-5a1 1 0 0 1-1-1v-5h-2v-2h2v-5a1 1 0 0 1 1-1h5v-2Zm-10 8v2h-2v-2h2Zm0-4v2h-2v-2h2Zm0-4v2h-2v-2h2Zm0-4v2h-2v-2h2Zm4 0v2h-2v-2h2Zm4 0v2h-2v-2h2Zm4 0v2h-2v-2h2Z"
                fill="#5C5F62"
            />
        </svg>
        <span>Slipp filene dine her for å laste opp</span>
    </section>

    <!-- EHF -->
    <ehf-viewer
        *ngIf="currentFile?._ehfMarkup"
        [file]="currentFile"
        [fileActions]="fileActions"
        (print)="print()"
        (popout)="openFileViewer()"
    >
    </ehf-viewer>

    <!-- Image -->
    <section class="image-section" *ngIf="currentFile && imgUrl && !currentFile?._ehfMarkup">
        <section class="uni-image-header" *ngIf="files?.length">
            <span
                class="prev bordered"
                *ngIf="files.length > 1 || currentFile.Pages > 1"
                (click)="previous()"
                [class.disabled]="currentPage === 1"
            >
                <i class="material-icons"> keyboard_arrow_left </i>
            </span>

            <section class="name-and-actions bordered">
                <span class="file-name" [title]="currentFile.Name">{{ currentFile.Name }}</span>

                <span *ngIf="currentFile.Pages > 1" class="page-info">
                    (side {{ currentPage }} av {{ currentFile.Pages }})
                </span>

                <section class="actions">
                    <i
                        *ngIf="canPrint && !currentFile.Deleted"
                        (click)="print()"
                        matTooltip="Skriv ut"
                        role="button"
                        class="material-icons"
                    >
                        print
                    </i>

                    <i
                        *ngIf="!currentFile.Deleted"
                        (click)="downloadFile()"
                        matTooltip="Last ned fil"
                        role="button"
                        class="material-icons"
                    >
                        get_app
                    </i>

                    <i
                        role="button"
                        matTooltip="Åpne i nytt vindu"
                        class="material-icons open-file-viewer"
                        (click)="openFileViewer()"
                    >
                        open_in_new
                    </i>

                    <i
                        role="button"
                        *ngFor="let action of fileActions"
                        matTooltip="{{ action.infotext }}"
                        class="material-icons open-file-viewer"
                        (click)="action.action()"
                    >
                        {{ action.icon }}
                    </i>
                </section>
            </section>

            <span
                class="next bordered"
                *ngIf="files.length > 1 || currentFile.Pages > 1"
                (click)="next()"
                [class.disabled]="currentPage === currentFile.Pages"
            >
                <i *ngIf="files.length > 1 || currentFile.Pages > 1" class="material-icons" role="button">
                    keyboard_arrow_right
                </i>
            </span>
        </section>

        <div *ngIf="uploading && !hideToolbar" class="uploading-progress-info">
            <span *ngIf="processingPercentage"> Prosesserer fil, {{ processingPercentage }}% ferdig </span>
            <span *ngIf="!processingPercentage"> Laster opp... </span>
        </div>

        <uni-image-file-comment [file]="currentFile"></uni-image-file-comment>

        <section #imageContainer class="image-container custom-scrollbar" (click)="openFileViewer()">
            <!-- Extra inline + relative section to make sure OCR word positioning works correctly -->
            <section style="display: inline-block; position: relative">
                <auth-img [src]="imgUrl" (load)="imageLoaded.next()"></auth-img>

                <span *ngIf="ocrWords?.length">
                    <span
                        *ngFor="let word of ocrWords"
                        @flashAnimation
                        class="image-word iw"
                        title="{{ word.text }}"
                        [ngStyle]="word._style"
                        (click)="onOCRWordClick(word, $event)"
                        [matMenuTriggerFor]="ocrMenu"
                    >
                    </span>

                    <mat-menu #ocrMenu="matMenu" [overlapTrigger]="false">
                        <section class="ocr-value-picker" (click)="$event.stopPropagation()">
                            <p>
                                Valgt verdi: <strong>{{ currentClickedWord?.text }}</strong>
                            </p>
                            <strong>Bruk verdi som</strong>
                            <button *ngFor="let value of ocrValues" mat-menu-item (click)="selectOCRWord(value.value)">
                                {{ value.label }}
                            </button>
                        </section>
                    </mat-menu>

                    <span
                        id="span-area-highlighter"
                        class="span-area-highlight-class"
                        [ngStyle]="highlightStyle"
                    ></span>
                </span>
            </section>
        </section>
    </section>

    <!-- Thumbnails -->
    <ul class="uni-thumbnail-list" *ngIf="!singleImage && files?.length > 1">
        <li *ngFor="let file of files" (click)="showFile(file)">
            <auth-img *ngIf="!file._ehfMarkup" [src]="file._thumbnailUrl"></auth-img>

            <section *ngIf="file._ehfMarkup" class="ehf-thumbnail">EHF</section>

            <mat-spinner *ngIf="!file._ehfMarkup && !file._thumbnailUrl" diameter="20"></mat-spinner>
        </li>
    </ul>
</article>
