import {
    Component,
    Input,
    Output,
    SimpleChanges,
    EventEmitter,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { trigger, transition, style, keyframes, animate } from '@angular/animations';

import { File } from '../../app/unientities';
import { AuthService } from '../../app/authService';
import { Subject, forkJoin, Subscription, of, Observable } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UniModalService, ConfirmActions, FileSplitModal } from '../uni-modal';
import { parseEHFData, generateEHFMarkup } from './ehf';
import { DomSanitizer } from '@angular/platform-browser';
import { rigDate } from '@app/components/common/utils/rig-date';
import { ErrorService } from '@app/services/common/errorService';
import { FileService } from '@app/services/common/fileService';
import { UniFilesService } from '@app/services/common/uniFilesService';
import fileViewerDataStore from 'src/assets/fileviewer-data-store.js';

export * from './ehf';
export * from './file-comment/file-comment';

export interface FileExtended extends File {
    _thumbnailUrl?: string;
    _imgUrls?: string[];
    _ehfMarkup?: string;
    _ehfAttachments?: any[];
    _processingCompleted?: boolean;
}

@Component({
    selector: 'uni-image',
    templateUrl: './uniImage.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('flashAnimation', [
            transition(':enter', [
                style({ backgroundColor: 'unset' }),
                animate(
                    '1s',
                    keyframes([
                        style({ backgroundColor: 'var(--color-c2a)', offset: 0.6 }),
                        style({ backgroundColor: 'unset', offset: 1 }),
                    ]),
                ),
            ]),
        ]),
    ],
})
export class UniImage {
    @ViewChild(MatMenuTrigger) ocrMenu: MatMenuTrigger;
    @ViewChild('imageContainer') imageContainer: ElementRef<HTMLElement>;

    @Input() entity: string;
    @Input() entityID: number;
    @Input() uploadWithoutEntity: boolean = false;
    @Input() readonly: boolean;
    @Input() rotateAllowed: boolean;
    @Input() splitAllowed: boolean;
    @Input() splitFileDialogAllowed: boolean;
    @Input() singleImage: boolean;
    @Input() showFileID: number;
    @Input() fileIDs: number[] = [];
    @Input() fileEntity: File;
    @Input() hideUploadInput: boolean;
    @Input() filterOnStatusCode: number | null;
    @Input() canUseInbox: boolean;
    @Input() accept: string;

    @Output() fileListReady: EventEmitter<FileExtended[]> = new EventEmitter(true); // needs to be async to avoid change detection timing issues when files are cached
    @Output() fileLoading = new EventEmitter<boolean>(true); // needs to be async to avoid change detection timing issues when files are cached
    @Output() imageDeleted: EventEmitter<FileExtended> = new EventEmitter();
    @Output() imageUnlinked: EventEmitter<FileExtended> = new EventEmitter();
    @Output() useWord = new EventEmitter();
    @Output() fileSplitCompleted = new EventEmitter();
    @Output() imageLoaded = new EventEmitter();

    private baseUrl: string = environment.BASE_URL_FILES;

    uploading: boolean;
    state = 'initial';

    files: FileExtended[] = [];
    currentFile: FileExtended;
    currentPage: number = 1;
    imgUrl: string;
    selectedEHFAttachment: any;
    canPrint: boolean;
    fileActions: { label: string; action: () => void; disabled?: boolean; icon?: string; infotext?: string }[];

    highlightStyle: any;
    currentClickedWord: any;
    ocrWords: any[] = [];
    ocrValues = [
        { label: 'Organisasjonsnr', value: 1 },
        { label: 'Fakturadato', value: 7 },
        { label: 'Forfallsdato', value: 8 },
        { label: 'Leveringsdato', value: 11 },
        { label: 'Fakturanummer', value: 5 },
        { label: 'Bankkonto', value: 3 },
        { label: 'KID', value: 4 },
        { label: 'Fakturabeløp', value: 6 },
        { label: 'Prosjekt', value: 12 },
        { label: 'Avdeling', value: 9 },
    ];

    processingPercentage: number = null;

    onDestroy$: Subject<any> = new Subject();

    resizeSubscription: Subscription;
    loadFilesDebouncer = new Subject();

    dragAndDropInProgress: boolean;

    constructor(
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef,
        private fileService: FileService,
        private modalService: UniModalService,
        private authService: AuthService,
        private uniFilesService: UniFilesService,
        public sanitizer: DomSanitizer,
    ) {
        /*
            Debounce loading files from ngOnChanges in case the host view has keyboard navigation
            and the user is holding down an arrow key. Without a debouncer this potentially results
            in us spamming the files api pretty hard.
        */
        this.loadFilesDebouncer.pipe(debounceTime(100)).subscribe(() => {
            this.refreshFiles();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.removeHighlight();
        this.currentPage = 1;
        this.currentFile = undefined;

        if ((changes['entity'] || changes['entityID']) && this.entity && this.isDefined(this.entityID)) {
            this.loadFilesDebouncer.next(undefined);
        } else if (changes['fileIDs']) {
            this.loadFilesDebouncer.next(undefined);
        } else if (changes['showFileID'] && this.files && this.files.length) {
            const file = this.files.find((f) => f.ID === this.showFileID);
            this.showFile(file);
        } else if (this.fileEntity) {
            this.setEhfDataOnFiles([this.fileEntity]).subscribe(
                (files) => this.setFiles(files),
                (err) => console.error(err),
            );
        }
    }

    ngOnDestroy() {
        this.setFileViewerData([]);
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
        this.loadFilesDebouncer.complete();
    }

    openFileViewer() {
        const baseUrl = window.location.href.split('/#/')[0];
        window.open(
            baseUrl + '/fileviewer.html',
            'Fileviewer',
            'menubar=0,scrollbars=1,height=950,width=965,left=0,top=8',
        );
    }

    downloadFile() {
        if (this.currentFile) {
            this.fileService.downloadFile(this.currentFile);
        }
    }

    public setOcrValues(values: any[]) {
        this.ocrValues = values;
    }

    public setOcrData(ocrResult) {
        if (ocrResult.OcrRawData) {
            const rawData = JSON.parse(ocrResult.OcrRawData);
            const words = rawData.AllWords;

            if (words && ocrResult.ImageWidth && ocrResult.ImageHeight) {
                words.forEach((word) => {
                    if (!word._style) {
                        word._style = {
                            height: (word.Height * 100) / ocrResult.ImageHeight + '%',
                            width: (word.Width * 100) / ocrResult.ImageWidth + '%',
                            left: (word.Left * 100) / ocrResult.ImageWidth + '%',
                            top: 'calc(' + (word.Top * 100) / ocrResult.ImageHeight + '% - 4.5px)',
                        };
                    }
                });
            }

            this.ocrWords = words;
            setTimeout(() => {
                this.state = 'initial';
                setTimeout(() => {
                    this.state = 'final';
                }, 2000);
            });
        } else {
            this.ocrWords = [];
        }

        this.cdr.markForCheck();
    }

    onOCRWordClick(word, event: MouseEvent) {
        event.stopPropagation();
        this.currentClickedWord = word;
    }

    selectOCRWord(useWordAs) {
        this.useWord.emit({
            word: this.currentClickedWord,
            propertyType: useWordAs,
        });

        this.currentClickedWord = null;
        this.ocrMenu.closeMenu();
    }

    private async setFileViewerData(files: FileExtended[]) {
        try {
            await fileViewerDataStore.set([]);

            if (files) {
                const data = files.map((file) => {
                    file._imgUrls = [];
                    for (let pageIndex = 1; pageIndex <= (file.Pages || 1); pageIndex++) {
                        file._imgUrls.push(this.generateImageUrl(file, pageIndex));
                    }

                    return {
                        imgUrls: file._imgUrls,
                        ehfMarkup: file._ehfMarkup,
                        ehfAttachments: file._ehfAttachments,
                    };
                });

                await fileViewerDataStore.set(data);
            }

            // Update guid in localStorage to let fileviewer know it needs to reload the data
            localStorage.setItem('fileviewer-guid', Date.now().toString());
        } catch (e) {
            console.error(e);
        }
    }

    private setEhfDataOnFiles(files: FileExtended[]) {
        const filesWithEhfData$ = files
            .filter((file) => !!file)
            .map((file) => {
                const filename = (file.Name || '').toLowerCase();
                const type = (file.ContentType || '').toLowerCase();
                if (type.startsWith('bis/') || filename.substring(filename.lastIndexOf('.')) === '.ehf') {
                    const ehfDataRequest = this.uniFilesService.getEhfData(file).pipe(
                        catchError((err) => {
                            console.error('Error loading EHF data', err);
                            return of(null);
                        }),
                        map((ehfData) => {
                            if (ehfData) {
                                const parsed = parseEHFData(ehfData);
                                if (parsed) {
                                    file._ehfMarkup = generateEHFMarkup(parsed);
                                    file._ehfAttachments = parsed.attachments;
                                }
                            }

                            return file;
                        }),
                    );

                    return ehfDataRequest;
                } else {
                    return of(file);
                }
            });

        return filesWithEhfData$.length ? forkJoin(filesWithEhfData$) : of([]);
    }

    public refreshFiles() {
        let request: Observable<FileExtended[]>;
        if (this.fileIDs && this.fileIDs.length && !(this.entity && this.entityID)) {
            const requestFilter = 'ID eq ' + this.fileIDs.join(' or ID eq ');
            request = this.fileService.GetAll(`filter=${requestFilter}`);
        } else if (this.entity && this.entityID) {
            request = this.fileService.getFilesOnEntity(this.entity, this.entityID);
        }

        if (request) {
            this.fileLoading.emit(true);
            this.cdr.markForCheck();

            request.pipe(switchMap((res) => this.setEhfDataOnFiles(res))).subscribe(
                (files) => this.setFiles(files),
                (err) => {
                    this.errorService.handle(err);
                    this.fileLoading.emit(false);
                    this.cdr.markForCheck();
                },
            );
        } else {
            this.files = [];
            this.setFileViewerData(this.files);
            this.cdr.markForCheck();
        }
    }

    private setFiles(files: File[]) {
        this.files = this.setThumbnailUrls(files).filter(
            (f) => !this.filterOnStatusCode || f.StatusCode === this.filterOnStatusCode,
        );
        this.setFileViewerData(this.files);
        this.fileListReady.emit(this.files);

        if (this.files.length) {
            this.currentPage = 1;
            const file = this.files.find((f) => f.ID === this.showFileID);
            this.showFile(file);
        }

        this.fileLoading.emit(false);
    }

    setThumbnailUrls(files: FileExtended[]): FileExtended[] {
        return (files || []).map((file) => {
            // Avoid blocking threads on the file server by checking processing
            // percentage before loading if the file is less than 5 minutes old
            const minutesSinceUpload = Math.abs(rigDate().diff(rigDate(file.CreatedAt), 'minutes'));
            if (minutesSinceUpload < 5 && !file._processingCompleted) {
                this.checkFileStatusAndSetThumbnailUrl(file);
            } else {
                file._thumbnailUrl = this.generateImageUrl(file);
            }

            return file;
        });
    }

    private checkFileStatusAndSetThumbnailUrl(file: FileExtended, currentAttempt = 0) {
        this.uniFilesService.getFileProcessingStatus(file.StorageReference).subscribe((res) => {
            // Status 0 = unknown (e.g file was created before queue handling was implemented)
            // Status 3 = finished
            if (res.Status === 0 || res.Status === 3 || currentAttempt > 100) {
                file._thumbnailUrl = this.generateImageUrl(file);
                this.cdr.markForCheck();
            } else {
                setTimeout(() => {
                    this.checkFileStatusAndSetThumbnailUrl(file, currentAttempt + 1);
                }, 1000);
            }
        });
    }

    getCurrentFile() {
        return this.currentFile;
    }

    addFileIDs(fileIDs: number[]) {
        this.fileIDs.push(...fileIDs);
        this.refreshFiles();
    }

    public fetchDocumentWithID(id: number) {
        this.fileIDs.push(id);
        this.refreshFiles();
    }

    private isDefined(value: any) {
        return value !== undefined && value !== null;
    }

    onDragover(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.readonly) {
            return false;
        }

        event.dataTransfer.dropEffect;
        this.dragAndDropInProgress = true;
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.dragAndDropInProgress = false;

        if (event.dataTransfer?.files?.length) {
            const files = Array.from(event.dataTransfer.files);
            this.fileService
                .batchUploadFiles(files, this.entity, this.entityID)
                .pipe(
                    switchMap((res) => {
                        const fileIds = res?.map((item) => item.ExternalId);
                        return this.fileService.GetAll(`filter=ID in (${fileIds})`);
                    }),
                )
                .subscribe({
                    next: (files) => this.onFilesUploaded(files),
                    error: (err) => this.errorService.handle(err),
                });
        }
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowLeft') {
            this.previous();
        } else if (event.key === 'ArrowRight') {
            this.next();
        }
    }

    public next() {
        if (this.currentFile.Pages > this.currentPage) {
            this.currentPage++;
            this.showFile(this.currentFile);
        } else {
            const index = this.files.findIndex((f) => f.ID === this.currentFile.ID);
            if (this.files[index + 1]) {
                this.showFile(this.files[index + 1]);
            }
        }
    }

    public previous() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.showFile(this.currentFile);
        } else {
            const index = this.files.findIndex((f) => f.ID === this.currentFile.ID);
            if (this.files[index - 1]) {
                this.showFile(this.files[index - 1]);
            }
        }
    }

    public print() {
        const fileName = (this.currentFile.Name || '').toLowerCase();
        if (fileName.includes('.pdf')) {
            const url =
                environment.BASE_URL_FILES + `/api/download?id=${this.currentFile.StorageReference}&attachment=false`;
            this.fileService.printFile(url, 'pdf');
        }
    }

    public splitFileDialog() {
        this.modalService
            .open(FileSplitModal, {
                data: this.currentFile,
            })
            .onClose.subscribe((res) => {
                if (res === 'ok') {
                    this.fileSplitCompleted.emit();
                }
            });
    }

    public splitFile() {
        const fileIndex = this.files.findIndex((f) => f.ID === this.currentFile.ID);

        this.modalService
            .confirm({
                header: 'Bekreft oppdeling av fil',
                message:
                    'Vennligst bekreft at du vil dele filen i to fra og med denne siden. ' +
                    'Siste del av filen vil legges tilbake i innboksen',
                buttonLabels: {
                    accept: 'Bekreft',
                    cancel: 'Avbryt',
                },
            })
            .onClose.subscribe((response) => {
                if (response === ConfirmActions.ACCEPT) {
                    this.uniFilesService.splitFile(this.files[fileIndex].StorageReference, this.currentPage).subscribe(
                        (splitFileResult) => {
                            this.fileService
                                .splitFile(
                                    this.files[fileIndex].ID,
                                    splitFileResult.FirstPart.ExternalId,
                                    splitFileResult.SecondPart.ExternalId,
                                )
                                .subscribe(
                                    (splitResultUE) => {
                                        this.files[fileIndex] = splitResultUE.FirstPart;
                                        this.setFileViewerData(this.files);

                                        if (this.currentPage > 1) {
                                            this.currentPage--;
                                        }

                                        this.checkFileStatusAndLoadImage(splitResultUE.FirstPart);
                                    },
                                    (err) => this.errorService.handle(err),
                                );
                        },
                        (err) => this.errorService.handle(err),
                    );
                }
            });
    }

    private rotate(clockwise = true) {
        this.uniFilesService.rotate(this.currentFile.StorageReference, this.currentPage, clockwise).subscribe(
            () => this.showFile(this.currentFile, true),
            (err) => this.errorService.handle(err),
        );
    }

    public async deleteImage() {
        const { ID: deleteFileID } = this.currentFile;

        const buttonLabels: any = {
            reject: 'Slett',
            cancel: 'Avbryt',
        };

        const canMoveToInbox = ['SupplierInvoice', 'JournalEntryLine', 'JournalEntry', 'BankStatementEntry'].includes(
            this.entity,
        );
        if (canMoveToInbox) {
            buttonLabels.accept = 'Legg tilbake i innboks';
        }

        this.modalService
            .confirm({
                header: 'Bekreft sletting',
                message: 'Vennligst bekreft sletting av fil',
                buttonLabels: buttonLabels,
                rejectButtonClass: 'secondary bad',
            })
            .onClose.pipe(
                switchMap((response) => {
                    let request: Observable<'sentToInbox' | 'canceled' | 'deleted'> = of('canceled');

                    switch (response) {
                        case ConfirmActions.REJECT:
                            request = this.fileService.Remove(deleteFileID).pipe(map(() => 'deleted'));
                        case ConfirmActions.ACCEPT:
                            if (
                                ['SupplierInvoice', 'JournalEntry', 'JournalEntryLine'].includes(this.entity) &&
                                this.entityID
                            ) {
                                request = this.fileService
                                    .unlinkFileFromInvoiceAndJournalEntry(
                                        this.entity,
                                        this.entityID,
                                        deleteFileID,
                                        this.entity === 'SupplierInvoice' ? 'JournalEntry' : 'SupplierInvoice',
                                    )
                                    .pipe(map(() => 'sentToInbox'));
                            } else if (this.entity && this.entityID) {
                                return this.fileService
                                    .unlinkFile(this.entity, this.entityID, deleteFileID)
                                    .pipe(map(() => 'sentToInbox'));
                            } else if (this.entity === 'JournalEntryLine') {
                                request = this.fileService
                                    .tagFileStatus(deleteFileID, 0, 'Upload')
                                    .pipe(map(() => 'sentToInbox'));
                            } else {
                                request.pipe(map(() => 'sentToInbox'));
                            }
                    }

                    return request;
                }),
            )
            .subscribe({
                next: (res) => {
                    if (res === 'deleted' || res === 'sentToInbox') {
                        this.removeImage(res === 'sentToInbox');
                    }
                },
                error: (err) => this.errorService.handle(err),
            });
    }

    private removeImage(isUnlink: boolean = false) {
        const file = this.currentFile;
        const index = this.files.findIndex((f) => f.ID === file.ID);

        if (index >= 0) {
            this.files.splice(index, 1);
            this.showFile(this.files[0]);

            this.fileListReady.emit(this.files);
            this.imageDeleted.emit(file);

            if (isUnlink) {
                this.imageUnlinked.emit(file);
            }
        }
    }

    private showFile(fileToShow: FileExtended, bustCache?: boolean) {
        this.selectedEHFAttachment = undefined;
        const file = fileToShow || (this.files && this.files[0]);
        if (file) {
            // Avoid blocking threads on the file server by checking processing
            // percentage before loading if the file is less than 5 minutes old
            const minutesSinceUpload = Math.abs(rigDate().diff(rigDate(file.CreatedAt), 'minutes'));
            if (minutesSinceUpload < 5 && !file._processingCompleted) {
                this.checkFileStatusAndLoadImage(file);
                return;
            }

            if (file !== this.currentFile) {
                this.currentPage = 1;
            }

            this.currentFile = file;
            this.removeHighlight();
            this.ocrWords = [];
            this.canPrint = file.Name && file.Name.includes('.pdf');

            this.fileActions = this.getFileActions();

            if (!file._ehfMarkup) {
                this.imgUrl = this.generateImageUrl(file, this.currentPage || 1, bustCache);
            }
        } else {
            this.currentFile = undefined;
            this.imgUrl = undefined;
            this.fileActions = undefined;
            this.canPrint = false;
        }

        this.cdr.markForCheck();
    }

    private getFileActions() {
        const actions = [];

        if (!this.readonly) {
            const label =
                this.entity === 'companysettings' || this.entity === 'JournalEntry'
                    ? 'Slett filen'
                    : 'Slett filen eller legg tilbake i innboksen';
            actions.push({
                label: 'Slett fil',
                action: () => this.deleteImage(),
                infotext: label,
                icon: 'delete_outline',
            });
        }

        if (this.rotateAllowed && !this.currentFile._ehfMarkup) {
            actions.push(
                {
                    label: 'Roter mot klokken',
                    action: () => this.rotate(false),
                    infotext: 'Roter mot klokken',
                    icon: 'rotate_left',
                },
                {
                    label: 'Roter med klokken',
                    action: () => this.rotate(true),
                    infotext: 'Roter med klokken',
                    icon: 'rotate_right',
                },
            );
        }

        const isPDF =
            this.currentFile.ContentType === 'application/pdf' || this.currentFile.Name.toLowerCase().endsWith('.pdf');
        if (this.currentFile.Pages > 1 && isPDF) {
            if (this.splitAllowed && !this.readonly) {
                actions.push({
                    label: 'Del fil i to fra denne siden',
                    action: () => this.splitFile(),
                    disabled: this.currentPage === 1,
                    infotext: 'Del fil i to fra denne siden',
                    icon: 'splitscreen',
                });
            }

            if (this.splitFileDialogAllowed) {
                actions.push({
                    label: 'Del opp fil',
                    action: () => this.splitFileDialog(),
                    infotext: 'Del opp fil',
                    icon: 'splitscreen',
                });
            }
        }

        return actions;
    }

    private generateImageUrl(file: FileExtended, pageOverride?: number, bustCache?: boolean): string {
        const endpoint = file.Deleted ? 'api/image/deleted' : 'api/image';
        let url =
            `${this.baseUrl}/${endpoint}` +
            `?key=${this.authService.activeCompany.Key}` +
            `&id=${file.StorageReference}` +
            `&page=${pageOverride || this.currentPage}`;

        if (bustCache) {
            url += `&t=${performance.now()}`;
        }

        return encodeURI(url);
    }

    onFilesUploaded(files: File[]) {
        if (!files?.length) {
            return;
        }

        const addFiles = () => {
            files.forEach((file) => {
                this.files.push(file);
                this.fileIDs.push(file.ID);
            });

            this.refreshFiles();
            this.setFileViewerData(this.files);
            this.checkFileStatusAndLoadImage(files[0]);
        };

        if (this.singleImage && this.files.length) {
            const oldFileID = this.files[0].ID;
            this.fileService.deleteOnEntity(this.entity, this.entityID, oldFileID).subscribe(
                () => addFiles(),
                (err) => this.errorService.handle(err),
            );
        } else {
            addFiles();
        }
    }

    private checkFileStatusAndLoadImage(file: FileExtended, currentAttempt: number = 0) {
        if (!this.processingPercentage) {
            this.processingPercentage = 0;
        }

        this.uniFilesService.getFileProcessingStatus(file.StorageReference).subscribe((res) => {
            if (this.processingPercentage !== res.ProcessedPercentage) {
                this.processingPercentage = res.ProcessedPercentage;
                this.cdr.markForCheck();
            }

            // Status 0 = unknown (e.g file was created before queue handling was implemented)
            // Status 3 = finished
            if (res.Status === 0 || res.Status === 3 || currentAttempt > 100) {
                file._processingCompleted = true;
                this.uploading = false;
                this.processingPercentage = null;

                this.showFile(file);
                this.fileListReady.emit(this.files);
            } else {
                setTimeout(() => {
                    this.checkFileStatusAndLoadImage(file, currentAttempt + 1);
                }, 1000);
            }
        });
    }

    // Coordinates param should contain positions top and left + height and width of highlight element
    // Height and width params is the sixe of the originally scanned document
    // styleObject is for custom style like size, color and shape on the highlight marker..
    public highlight(coordinates: number[], width: number, height: number, styleObject?: any) {
        if ((!coordinates || coordinates.length < 4) && !styleObject) {
            return;
        }

        if (styleObject) {
            this.highlightStyle = styleObject;
        } else {
            this.highlightStyle = {
                display: 'block',
                height: (coordinates[3] * 100) / height + '%',
                width: (coordinates[2] * 100) / width + '%',
                left: (coordinates[0] * 100) / width + '%',
                top: 'calc(' + (coordinates[1] * 100) / height + '% - 4.5px)',
            };
        }

        this.cdr.markForCheck();
    }

    public removeHighlight() {
        this.highlightStyle = {
            display: 'none',
        };

        this.cdr.markForCheck();
    }
}
