import { Injectable } from '@angular/core';
import { empty, Observable, ObservableInput } from 'rxjs';
import { Logger } from '../../../framework/core/logger';
import { ToastService, ToastType } from '../../../framework/uniToast/toastService';
import { ComplexValidationRule, EntityValidationRule } from '../../unientities';
import { ErrorTranslationsService } from '@app/services/common/error-translations.service';

@Injectable({ providedIn: 'root' })
export class ErrorService {
    constructor(
        public logger: Logger,
        private toastService: ToastService,
        private errorTranslationService: ErrorTranslationsService,
    ) {}

    public handle(error: any) {
        this.handleWithMessage(error, null);
    }

    public handleRxCatch(err: any, caught: Observable<any>): ObservableInput<any> {
        this.handleWithMessage(err, null);
        return empty();
    }

    public handleWithMessage(error: any, toastMsg: string) {
        const message = this.extractMessage(error);

        if (toastMsg) {
            error.customMessage = toastMsg;
        }

        if (error.status === 401) {
            return;
        }

        console.error(error);
        this.logger.logError(error);
        this.addErrorToast(toastMsg || message);
    }

    public extractMessage(err): string {
        if (!err) {
            return;
        }

        if (typeof err === 'string') {
            return this.errorTranslationService.translate(err);
        }

        const errorBody = this.getErrorBody(err);
        if (typeof errorBody === 'string') {
            return this.errorTranslationService.translate(errorBody);
        }

        if (errorBody) {
            if (errorBody.message || errorBody.Message) {
                // sometimes error message can be a json string?
                const errorObj = this.tryParseJSONObject(errorBody.message || errorBody.Message);
                if (errorObj) {
                    return this.errorTranslationService.translate(errorObj.Message);
                }

                return this.errorTranslationService.translate(errorBody.message || errorBody.Message);
            } else if (errorBody.Messages) {
                if (errorBody.Messages.length > 0) {
                    return errorBody.Messages.map((m) => {
                        return this.errorTranslationService.translate(m.Message);
                    }).join('<br />');
                } else {
                    return '[Unparsable error occurred, see logs for more info]';
                }
            } else if (err.status === 400) {
                const messages = this.extractValidationResults(errorBody);
                const translatedMessages = messages
                    ?.map((x) => this.errorTranslationService.translate(x))
                    .join('<br />');
                return translatedMessages;
            }
        } else if (err.status === 429) {
            const errorMessage = 'For mange forespørsler. Vennligst prøv igjen.';
            return errorMessage;
        } else if (err.status >= 399 && err.statusText === 'OK') {
            const errorMessage = 'Noe gikk galt. Vennligst prøv igjen.';
            return errorMessage;
        } else {
            return this.errorTranslationService.translate(err.statusText) || '';
        }
    }

    private isHttpError(err: any) {
        return !!err?.headers && !!err?.status;
    }

    public extractValidationResults(error: any): string[] {
        const flatten = (arr, rest) => arr.concat(rest);

        function findValue(object: any) {
            const validationErrorKey = '_validationResults';
            const validationErrors: string[] = [];
            if (object) {
                Object.keys(object).forEach((key) => {
                    const value = object[key];
                    if (value) {
                        if (key === validationErrorKey) {
                            Object.keys(value)
                                .map((k) => value[k])
                                .reduce(flatten, [])
                                .map((x) => `${x.Message} on entity ${x.EntityType}`)
                                .forEach((message) => validationErrors.push(message));
                        } else if (Array.isArray(value)) {
                            Array.prototype.push.apply(
                                validationErrors,
                                value.map((v) => findValue(v)).reduce(flatten, []),
                            );
                        } else if (typeof value === 'object') {
                            Array.prototype.push.apply(validationErrors, findValue(value));
                        }
                    }
                });
            }

            return validationErrors;
        }

        return findValue(error);
    }

    public extractComplexValidationRules(error: any): ComplexValidationRule[] {
        const complexValidationRuleKey = 'ComplexValidationRule';
        return this.extractValidationMessages(this.getErrorBody(error))
            .filter((message) => !!message[complexValidationRuleKey])
            .map((message) => message[complexValidationRuleKey]);
    }

    public extractEntityValidationRules(error: any): EntityValidationRule[] {
        return this.extractValidationMessages(this.getErrorBody(error))
            .filter((message) => !!message[EntityValidationRule.EntityType])
            .map((message) => message[EntityValidationRule.EntityType]);
    }

    public extractValidationMessages(obj: any) {
        return [
            ...this.extractValidationMessagesRecursively(obj),
            ...(obj.Messages || []),
            obj.Message,
            obj.message,
        ].filter((x) => !!x);
    }

    private extractValidationMessagesRecursively(obj: any): any[] {
        const validationErrorKey = '_validationResults';

        if (!obj) {
            return [];
        }

        return Object.keys(obj)
            .map((key) => {
                const value = obj[key];
                if (!value) {
                    return [];
                }
                if (key === validationErrorKey) {
                    return Object.keys(value)
                        .map((k) => <any[]>value[k])
                        .reduce((acc, curr) => [...acc, ...curr], []);
                }

                if (Array.isArray(value)) {
                    return value
                        .map((element) => this.extractValidationMessagesRecursively(element))
                        .reduce((acc, curr) => [...acc, ...curr], []);
                }

                if (typeof value === 'object') {
                    return this.extractValidationMessagesRecursively(value);
                }

                return [];
            })
            .reduce((acc, curr) => [...acc, ...curr], []);
    }

    private getErrorBody(err: any) {
        return this.isHttpError(err) ? err.error : err;
    }

    public addErrorToast(message: string) {
        if (message && message.length < 60) {
            this.toastService.addToast(message, ToastType.warn, 10);
        } else {
            this.toastService.addToast('En feil oppstod', ToastType.warn, null, message);
        }
    }

    private tryParseJSONObject(jsonString: string) {
        try {
            var o = JSON.parse(jsonString);

            if (o && typeof o === 'object') {
                return o;
            }
        } catch (e) {}

        return false;
    }
}
