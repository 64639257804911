export enum DimensionTypes {
    Project = 1,
    Department = 2,
    Responsible = 3,
    Region = 4,
}

export const dimensionTypeToEntityName = {
    [DimensionTypes.Project]: 'Project',
    [DimensionTypes.Department]: 'Department',
    [DimensionTypes.Responsible]: 'Responsible',
    [DimensionTypes.Region]: 'Region',
} as const;

export const dimensionTypeToDisplayName = {
    [DimensionTypes.Project]: 'Prosjekt',
    [DimensionTypes.Department]: 'Avdeling',
    [DimensionTypes.Responsible]: 'Ansvar',
    [DimensionTypes.Region]: 'Område',
} as const;
