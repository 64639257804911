import { LicenseEntityStatus } from '@uni-entities';
import { TaxMandatoryType } from './onboarding-models';

// Goes through each unrequired key in T and makes a DeepPartial of the value.
// Basically it makes everything optional, even expanding into other referenced interfaces, which Partial doesn't do.
type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export interface ElsaAgreement {
    ID: number;
    AgreementText: string;
    AgreementStatus: ElsaAgreementStatus;
    ProductID: number;
    Name: string;
    DownloadUrl: string;
    AcceptButtonLabel?: string;
    RejectButtonLabel?: string;
    ContractType?: ContractType;
}

export enum ElsaAgreementStatus {
    Draft = 0,
    Active = 5,
    Replaced = 10,
}

export interface ElsaCustomerPartial extends DeepPartial<ElsaCustomer> {}

export interface ElsaCustomer {
    ID: number;
    Name: string;
    OrgNumber: string;
    CompanyKey: string;
    ContactPerson: string;
    ContactEmail: string;
    ContactPhone: string;
    CustomerRoamingUsers?: any[];
    Managers?: ElsaManager[];
    Contracts?: ElsaContract[];
    CompanyTypeID?: number;
    PersonalNumber?: string;
    IsBankCustomer: boolean;
    SignUpReferrer: string;
    ProspectID?: number;
    CustomerType: ElsaCustomerType;
    HasExternalAccountant: boolean;
    DepartmentID?: number;
    BureauOnboardingType?: ElsaBureauOnboardingType;
    CustomerAddress?: ElsaCustomerAddress;
}

export enum ElsaCustomerType {
    Standard = 0,
    Bureau = 5,
}

export interface ElsaCustomerAddress {
    ID: number;
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    PostalCode: string;
    City: string;
    Country: string;
}

export interface ElsaManager {
    AccessRole: ManagerAccessRole;
    ID: number;
    User: ElsaManagerUser;
}

export interface ElsaManagerUser {
    Email: string;
    ID: number;
    Identity: string;
    Name: string;
    PhoneNumber: string;
}

export enum ManagerAccessRole {
    Read = 0,
    Manage = 1,
    Owner = 2,
    Support = 3,
}

export enum ElsaBureauOnboardingType {
    OnlyBureau = 10,
    OnlyStandard = 20,
    Both = 30,
}

export interface ElsaCompanyLicense {
    ID: number;
    ContractID: number;
    Contract: ElsaContract;
    CompanyName: string;
    CompanyKey: string;
    OrgNumber: string;
    StatusCode: LicenseEntityStatus;
    EndDate: string;
    IsDeleted: boolean;
    UpdatedAt: Date;
    UpdatedBy: string;
    UpdatedByEmail: string;
    TwoFactorEnabled?: boolean;
    ExternalCustomerID: string;
    TemplateCompanyKey?: string;
    UserLicenses?: ElsaUserLicense[];
    CompanyDetails?: ElsaCompanySettingsDto;
}

export interface ElsaCompanySettingsDto {
    CompanyName?: string;
    OrganizationNumber?: string;
    WebAddress?: string;
    Email?: string;
    PhoneNumber?: string;
    Address?: string;
    PostalCode?: string;
    City?: string;
    CountryCode?: string;
    Country?: string;
    CompanyBankAccount?: ElsaBankAccountDto;
    BankAccounts?: ElsaBankAccountDto[];
    TaxMandatoryType?: TaxMandatoryType;
}

export interface ElsaBankAccountDto {
    Label?: string;
    AccountNumber?: string;
    BankAccountType?: string;
    BankAccountSettings?: ElsaBankAccountSettingDto;
}

export interface ElsaBankAccountSettingDto {
    IsTaxAccount?: boolean;
    CurrencyCode?: string;
    BalanceBooked?: number;
    BalanceAvailable?: number;
}

export interface ElsaPurchase {
    ID: number;
    ProductID: number;
    ProductName?: string;
    GlobalIdentity?: string;
    Deleted?: boolean;
    PurchaseStatus?: ElsaPurchaseStatus;
    StartDate?: Date;
    EndDate?: Date;
    CreatedByEmail?: string;
    CreatedBy?: string;
}

export enum ElsaPurchaseStatus {
    Accepted = 1,
    Rejected = 5,
    Pending = 10,
    RequestSent = 20,
    RequestApproved = 25,
    RequestRejected = 30,
    ConsentRequired = 35,
}

export interface ElsaContract {
    ID: number;
    CustomerID: number;
    Customer: ElsaCustomer;
    ContractType: ContractType;
    ContractTypes: ElsaContractType;
    StatusCode: number;
    StartDate: Date;
    EndDate?: Date;
    SettledUntil?: Date;
    Note?: any;
    Limit: number;
    AgreementAcceptances: any[];
    TwoFactorEnabled?: boolean;
    Name: string;
    ExternalCustomerID: string;
    CampaignCode?: string;
    CompanyLicenses?: ElsaCompanyLicense[];
}

export interface ElsaContractType {
    ContractType: number;
    IsActive: boolean;
    IsDefault: boolean;
    IsPublic: boolean;
    ForceSameBank: boolean;
    Label: string;
    MaxCompanies: number;
    MaxUsers: number;
    Name: string;
    MaxJournalEntries?: number;
    UpgradeWhenMaxJournalEntries: boolean;
    TargetContractTypeOnMaxJournalEntries?: number;
    ProductContractTypes?: ElsaProductContractType[];
    ContractTypeFeatures?: ElsaContractTypeFeature[];
    BulletPoints?: { Text: string }[];
    CustomerType?: number;
}

export interface ElsaProductContractType {
    ContractType: number;
    ID: number;
    IsDefaultProduct: boolean;
    IsMandatoryProduct: boolean;
    ProductID: 11;
    Product?: ElsaProduct;
}

export interface ElsaContractTypeFeature {
    FeatureID: number;
    Feature: ElsaFeature;
    ContractType: number;
    ContractTypes: ElsaContractType;
}

export interface ElsaCategory {
    ID: number;
    Name: string;
    Features?: ElsaFeature[];
}

export interface ElsaFeature {
    ID: number;
    Text: string;
    CategoryID: number;
    ContainsContractTypes: number[];
    Tooltip?: string;
    Checkmarks: boolean[];
}

export enum ContractType {
    Demo = 0,
    Internal = 1,
    Partner = 3,
    Pilot = 4,
    Training = 5,
    Standard = 10,
    Bureau = 11,
    NonProfit = 12,
    BureauAnnual = 13,
    BureauInvoiceApproval = 14,
    Level0 = 20,
    Mini = 21,
    Plus = 22,
    Complete = 23,
    Level4 = 24,
    Level5 = 25,
    Level6 = 26,
    Level7 = 27,
    Level8 = 28,
    Level9 = 29,
}

export interface ElsaProduct {
    ID: number;
    ButtonLabel: string;
    ButtonLink: string;
    CategoryName?: string;
    Description: string;
    HtmlContent?: string;
    MarkdownContent?: string;
    VideoUrl?: string;
    IconBackgroundColor?: string;
    IconReference?: string;
    ImageReference?: string;
    ClientID: string;
    IsBundle: boolean;
    IsBillable: boolean;
    IsMonthly: boolean;
    IsPerAnnum: boolean;
    IsPerTransaction: boolean;
    IsPerUser: boolean;
    HideFromMarketplace: boolean;
    Label: string;
    ListOfRoles: string;
    DefaultRoles?: string;
    Name: string;
    ParentProducts?: string[];
    Price: number;
    ProductPrices?: ElsaProductPrice[];
    ProductAgreement: ElsaAgreement;
    ProductKey: string;
    ProductStatus: ElsaProductStatusCode;
    ProductStatusName: string;
    ProductType: ElsaProductType;
    ProductTypeName: string;
    Tags: string[];
    SubProducts?: ElsaProduct[];
    IsMandatoryProduct?: boolean;
    IsDefaultProduct?: boolean;
    AgencyOrgNumber?: string;
    BillingLevel: ElsaProductBillingLevel;
}

export enum ElsaProductStatusCode {
    Live = 0,
    SoonToBeLaunched = 1,
    DevelopmentCandidate = 2,
    Inactive = 3,
}

export enum ElsaProductType {
    All = -1,
    Module = 0,
    Bundle = 1,
    Integration = 2,
    Extension = 3,
    BankProduct = 4,
    FileImport = 5,
    Package = 6,
    AccountingAgencyCompany = 7,
}

export enum ElsaProductBillingLevel {
    PerContract = 0,
    PerCompany = 1,
    PerUser = 2,
    PerTransaction = 3,
}

export interface ElsaProductPrice {
    ContractType: ContractType;
    FromDate: Date;
    ID: number;
    Price: number;
    ProductID: number;
    ProductPriceType: ElsaProductPriceType;
    Steps: ElsaStep[];
    ToDate: Date;
    UserLicenseType: ElsaUserLicenseType;
}

export interface ElsaStep {
    ID?: number;
    Name?: string;
    ContractType: ContractType;
    UserLicenseType: ElsaUserLicenseType;
    ProductID: number;
    StartValue: number;
    EndValue?: number;
    Price: number;
    ProductPriceID: number;
}

export enum ElsaProductPriceType {
    Default = 0, // monthly
    Yearly = 1,
}

export enum ElsaUserLicenseType {
    Standard = 0,
    Accountant = 1,
    Revision = 2,
    Training = 3,
    Support = 10,
    Internal = 11,
    Server = 12,
}

export interface ElsaUserLicense {
    ID: number;
    CompanyLicenseID: number;
    CompanyLicense: ElsaCompanyLicense;
    UserLicenseType: ElsaUserLicenseType;
    StatusCode: LicenseEntityStatus;
    UserIdentity: string;
    UserLicenseKey: string;
    UserName: string;
    Email: string;
    IsAdmin?: boolean;
}

export enum ElsaCustomerAccessRole {
    Read = 0,
    Manage = 1,
    Owner = 2,
}

export interface BillingDataItem {
    ProductID: number;
    ProductName: string;
    Days: number;
    Amount: number;
    Unit: string;
    Price: number;
    DiscountPrc: number;
    Sum: number;
    Details: { Name: string; Counter: number; Tags?: string[] }[];
}

export interface BillingData {
    BillingHistoryID: number;
    CustomerName: string;
    CustomerID: number;
    ContractExternalCustomerID: string;
    ContractID: number;
    ContractName: string;
    ContractType: number;
    FromDate: string;
    ToDate: string;
    Total: number;
    TotalDiscount: number;
    OrderDays: number;
    Items: BillingDataItem[];
    RelatedOrders: BillingData[];
    SettledUntil?: Date;
}

export interface ElsaSupportUserDTO {
    ID: number;
    DisplayName: string;
    Email: string;
    SupportType: number;
    GlobalIdentity: string;
    StatusCode: number;
}

export interface SupportArticleMapping {
    ID: number;
    Title: string;
    ApplicationRoute: string;
    ArticleUrl: string;
    ArticleTitle: string;
    Description: string;
    StatusCode: StatuCodeSupportArticleMapping;
}

export enum StatuCodeSupportArticleMapping {
    Draft = 0,
    Active = 1,
    Archived = 2,
}

export interface ElsaCustomerProspect {
    ID?: number;
    OrgNumber?: string;
    CompanyName?: string;
    PersonalNumber?: string;
    ContactPerson?: string;
    ContactPhone?: string;
    ContactEmail?: string;
    ExternalPurchaseID?: string;
    ExternalID?: string;
    CustomerID?: number;
    CreatedAt?: Date;
    InvitedAt?: Date;
    AcceptedAt?: Date;
    StatusCode?: ElsaCustomerProspectStatus;
    SendInviteNow: boolean;
    AccountNumber?: string;
    AgencyCompanyKey?: string;
    ProspectType?: ElsaCustomerProspectType;
    InvitedByCompanyKey?: string;
    InvitedByUser?: string;
    DetailsID?: number;
    Details?: ElsaCustomerProspectDetails;
}

export enum ElsaCustomerProspectStatus {
    NotInvited = 0,
    Invited = 1,
    Accepted = 2,
    Cancelled = 3,
}

export enum ElsaCustomerProspectType {
    Regular = 0,
    Agency = 1,
    AgencyLead = 2,
}

export interface ElsaCustomerProspectDetails {
    Contact?: string;
    CustomerComment?: string;
    Email?: string;
    HasConsentedToShareData?: boolean;
    ID?: number;
    Phone?: string;
    PreferredContactOption?: string;
}

export interface AccountingAgency {
    Name: string;
    OrganizationNumber: string;
    IsDNBPartner: boolean;
}

export interface SoftRigAgencyRequest {
    CompanyKey?: string;
    OrgNo?: string;
    Name?: string;
    Created?: Date;
}
