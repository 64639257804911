import { Component, EventEmitter } from '@angular/core';
import { of, forkJoin, Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { IModalOptions, IUniModal } from '../../interfaces';
import { User } from '@uni-entities';
import { ElsaProduct, ElsaPurchase, ElsaProductType, ElsaUserLicense, ElsaUserLicenseType } from '@app/models';
import { UntypedFormControl } from '@angular/forms';
import { ErrorService } from '@app/services/common/errorService';
import { UserService } from '@app/services/common/userService';
import { ElsaCustomersService } from '@app/services/elsa/elsaCustomersService';
import { ElsaProductService } from '@app/services/elsa/elsaProductService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';
import { AuthService } from '@app/authService';

@Component({
    selector: 'product-purchases-modal',
    templateUrl: './product-purchases-modal.html',
    styleUrls: ['./product-purchases-modal.sass'],
})
export class ProductPurchasesModal implements IUniModal {
    options: IModalOptions = {};
    onClose: EventEmitter<any> = new EventEmitter();

    busy: boolean;
    users: User[];

    checkAll: boolean;
    filterControl: UntypedFormControl = new UntypedFormControl('');
    purchasesMatrix: any[];

    singleProductMode: boolean;
    products: ElsaProduct[];
    purchases: ElsaPurchase[] = [];

    allPurchasesMatrix: {
        userID: number;
        userDisplayName: string;
        purchases: ElsaPurchase[];
    }[];

    constructor(
        private errorService: ErrorService,
        private userService: UserService,
        private purchaseService: ElsaPurchaseService,
        private productService: ElsaProductService,
        private customerService: ElsaCustomersService,
        private authService: AuthService,
    ) {
        this.filterControl.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe((value) => this.filterItems(value));
    }

    ngOnInit() {
        this.busy = true;
        const data = this.options.data || {};
        let productRequest: Observable<ElsaProduct[]>;

        if (data.product) {
            this.singleProductMode = true;
            productRequest = of([data.product]);
        } else if (data.products) {
            productRequest = of([...data.products]);
        } else {
            const contractType = this.authService.currentUser.License.ContractType.TypeID;
            productRequest = this.productService.getProductsOnContractTypes(contractType);
        }

        const requests = <any>[this.purchaseService.getAll(), productRequest];

        if (!data.users) {
            requests.push(this.userService.GetAll());
            requests.push(this.customerService.getUserLicensesOnCompany());
        } else {
            this.users = data.users;
        }

        forkJoin(requests).subscribe(
            (res) => {
                this.purchases = <ElsaPurchase[]>res[0];
                this.products = (<ElsaProduct[]>res[1] || []).filter((product) => {
                    return (
                        product.IsPerUser &&
                        (product.ProductType === ElsaProductType.Module ||
                            product.ProductType === ElsaProductType.Package)
                    );
                });

                if (res[2]) {
                    this.users = this.mapUsers(<User[]>res[2], <ElsaUserLicense[]>res[3]);
                } else {
                    this.users = this.users.filter((user) => {
                        return !(
                            user['_userLicense']?.UserLicenseType === ElsaUserLicenseType.Accountant ||
                            user['_userLicense']?.UserLicenseType === ElsaUserLicenseType.Revision ||
                            user['_userLicense']?.UserLicenseType === ElsaUserLicenseType.Support
                        );
                    });
                }

                this.buildProductMatrix();
                this.checkAll = this.purchasesMatrix?.every((item) => item.purchases[0]['_active']);
                this.busy = false;
            },
            (err) => {
                this.busy = false;
                this.errorService.handle(err);
            },
        );
    }

    mapUsers(users: User[], userLicenses: ElsaUserLicense[]): User[] {
        // filter away protected, server, accountant, revision, support and users missing userlicense
        return users
            .filter(
                (user) =>
                    !user.Protected &&
                    !userLicenses?.some((ul) => {
                        return (
                            ul.UserIdentity?.toLowerCase() === user.GlobalIdentity?.toLowerCase() &&
                            (ul.UserLicenseType === ElsaUserLicenseType.Server ||
                                ul.UserLicenseType === ElsaUserLicenseType.Accountant ||
                                ul.UserLicenseType === ElsaUserLicenseType.Revision ||
                                ul.UserLicenseType === ElsaUserLicenseType.Support)
                        );
                    }),
            )
            .map((user) => {
                user['_userLicense'] = userLicenses?.find((ul) => {
                    if (!ul.Email) return ul.UserIdentity?.toLowerCase() === user.GlobalIdentity?.toLowerCase();
                    return ul.Email.toLowerCase() === user.Email?.toLowerCase();
                });

                return user;
            })
            .filter((user) => !!user['_userLicense']);
    }

    save() {
        const updates = [];
        this.purchasesMatrix.forEach((entry) => {
            entry.purchases.forEach((purchase: ElsaPurchase) => {
                if (purchase['_active'] && !purchase.ID) {
                    updates.push(purchase);
                } else if (!purchase['_active'] && purchase.ID) {
                    purchase.Deleted = true;
                    updates.push(purchase);
                }
            });
        });

        this.busy = true;
        if (updates.length) {
            this.purchaseService.massUpdate(updates).subscribe(
                () => {
                    this.onClose.emit(true);
                },
                (err) => {
                    this.errorService.handle(err);
                    this.busy = false;
                },
            );
        } else {
            this.onClose.emit(false);
        }
    }

    private buildProductMatrix() {
        this.purchasesMatrix = this.allPurchasesMatrix = this.users.map((user) => {
            const entry = {
                userID: user.ID,
                userDisplayName: user.DisplayName || user.Email,
                purchases: [],
            };

            entry.purchases = this.products.map((product) => {
                let purchase = this.purchases.find((p) => {
                    return p.GlobalIdentity === user.GlobalIdentity && p.ProductID === product.ID;
                });

                if (purchase) {
                    purchase['_active'] = true;
                } else {
                    purchase = {
                        ID: null,
                        GlobalIdentity: user.GlobalIdentity,
                        ProductID: product.ID,
                    };

                    purchase['_active'] = false;
                }

                purchase['_readonly'] = product.IsMandatoryProduct;

                return purchase;
            });

            return entry;
        });
    }

    togglePurchase(purchase) {
        if (!purchase['_readonly']) {
            purchase['_active'] = !purchase['_active'];

            this.checkAll = this.purchasesMatrix.every((item) => item.purchases[0]['_active']);
        }
    }

    onCheckAllChange() {
        this.purchasesMatrix.forEach((item) => (item.purchases[0]['_active'] = this.checkAll));
    }

    private filterItems(filterText: string) {
        const filterLowerCase = filterText.toLowerCase();
        this.purchasesMatrix = this.allPurchasesMatrix.filter((item) => {
            return item.userDisplayName.toLowerCase().includes(filterLowerCase);
        });
    }
}
