import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { Account, AccountGroup, StatusCode } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { StatisticsService } from '../common/statisticsService';
import { catchError, map, publishReplay, refCount, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { theme, THEMES } from 'src/themes/theme';
import { UniCache } from '@app/cache';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';

@Injectable({ providedIn: 'root' })
export class AccountService extends BizHttp<Account> {
    accountSearchCache = new UniCache(5, 50);

    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
    ) {
        super(http);

        this.relativeURL = Account.RelativeUrl;
        this.entityType = Account.EntityType;
        this.DefaultOrderBy = 'AccountNumber';

        this.cacheInvalidated$.subscribe(() => this.accountSearchCache.clear());
    }

    public searchAccounts(filter: string, top: number = 500, orderby = 'AccountNumber') {
        return this.statisticsService
            .GetAll(
                `model=Account` +
                    `&top=${top}` +
                    `&filter=${filter}` +
                    `&orderby=${orderby}` +
                    `&expand=TopLevelAccountGroup` +
                    `&join=Account.Customerid eq Customer.ID and Account.supplierid eq Supplier.id` +
                    `&select=Account.ID as AccountID,Account.AccountID as MainAccountID,Account.AccountNumber as AccountAccountNumber,` +
                    `Account.AccountName as AccountAccountName,Account.UsePostPost as AccountUsePostPost,Account.Locked as AccountLocked,` +
                    `Account.LockManualPosts as AccountLockManualPosts,VatTypeID as VatTypeID,TopLevelAccountGroup.GroupNumber,` +
                    `Account.CustomerID as AccountCustomerID,Account.SupplierID as AccountSupplierID,` +
                    `Account.UseVatDeductionGroupID as AccountUseVatDeductionGroupID,Supplier.StatusCode as SupplierStatusCode,` +
                    `Customer.StatusCode as CustomerStatusCode,Keywords as AccountKeywords,Description as AccountDescription,Account.Visible as AccountVisible`,
            )
            .pipe(
                map((x) => (x.Data ? x.Data : [])),
                map((x) => this.mapStatisticsToAccountObjects(x)),
            );
    }

    public getAccountDigitLength() {
        return this.statisticsService
            .GetAll(
                'model=account' +
                    '&select=length(accountnumber) as Digits,count(id) as Count' +
                    '&filter=toplevelaccountgroupid gt 0 and isnull(customerid,0) eq 0 and isnull(supplierid,0) eq 0 and visible eq 1' +
                    '&top=1&orderby=count(id) desc',
            )
            .pipe(
                map((result) => {
                    return result && result.Data && result.Data.length > 0 ? result.Data[0].Digits : 4;
                }),
            );
    }

    public getSaftMappingAccounts(): Observable<any> {
        return this.http
            .asGET()
            .withEndPoint(this.relativeURL + '?action=saftmapping-accounts')
            .send()
            .pipe(map((res) => res.body));
    }

    public setSaftMappings(accountNumbers: number[] = null) {
        return this.http
            .asPUT()
            .withEndPoint(this.relativeURL + '?action=set-saftmappings')
            .withBody(accountNumbers)
            .send();
    }

    public ControlAndSetAccountMappings(
        checkSaftMapping: boolean,
        checkAltinnMapping: boolean,
        updateOnlyMissing: boolean,
    ) {
        const params = `checkSaftMapping=${checkSaftMapping}&checkAltinnMapping=${checkAltinnMapping}&updateOnlyMissing=${updateOnlyMissing}`;
        return this.http
            .asPUT()
            .withEndPoint(this.relativeURL + `?action=control-and-fill-out-missing-account-links&${params}`)
            .send();
    }

    public ControlAndSetMissingAccountGroups() {
        return this.http
            .asPUT()
            .withEndPoint(this.relativeURL + '?action=control-and-set-missing-accountgroups')
            .send();
    }

    public GetFinancialKpi(year: number, fromPeriod = 1, toPeriod = 12) {
        let route = `?action=get-kpi&financialyear=${year}&period=${fromPeriod}-${toPeriod}`;
        return this.http
            .asGET()
            .withEndPoint(this.relativeURL + route)
            .send()
            .pipe(map((res) => res.body));
    }

    private mapStatisticsToAccountObjects(statisticsData: any[]): Account[] {
        const accounts = [];

        statisticsData.forEach((data) => {
            const account: Account = new Account();
            account.ID = data.AccountID;
            account.AccountNumber = data.AccountAccountNumber;
            account.AccountName = data.AccountAccountName;
            account.AccountID = data.MainAccountID;
            account.VatTypeID = data.VatTypeID;
            account.CustomerID = data.AccountCustomerID;
            account.SupplierID = data.AccountSupplierID;
            account.UseVatDeductionGroupID = data.AccountUseVatDeductionGroupID;
            account.UsePostPost = data.AccountUsePostPost;
            account.Locked = data.AccountLocked;
            account.LockManualPosts = data.AccountLockManualPosts;
            account.StatusCode = data.SupplierStatusCode || data.CustomerStatusCode;
            account.Visible = data.AccountVisible;

            account['Keywords'] = data.AccountKeywords;
            account['Description'] = data.AccountDescription;

            if (data.TopLevelAccountGroupGroupNumber) {
                account.TopLevelAccountGroup = new AccountGroup();
                account.TopLevelAccountGroup.GroupNumber = data.TopLevelAccountGroupGroupNumber;
            }

            accounts.push(account);
        });

        return accounts;
    }

    public addMandatoryDimensions(data: any) {
        const urldata = [
            'FromAccountNo=' + data.FromAccountNo,
            'ToAccountNo=' + data.ToAccountNo,
            'DimensionNo=' + data.DimensionNo,
            'MandatoryType=' + data.MandatoryType,
        ];
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint('accountmandatorydimension?action=add-accounts-mandatory-dimensions&' + urldata.join('&'))
            .send()
            .pipe(map((res) => res.body));
    }

    public checkLinkedBankAccountsAndPostPost(FromAccountNumber: any, ToAccountNumber?: any) {
        if (!ToAccountNumber) {
            ToAccountNumber = FromAccountNumber;
        }
        return this.statisticsService
            .GetAll(
                `model=Account` +
                    `&select=account.*,bankaccount.ID,bankaccount.AccountNumber` +
                    `&filter=Accountnumber ge ${FromAccountNumber} and  Accountnumber le ${ToAccountNumber} and bankaccount.ID gt 0` +
                    `&join=Account.id eq Bankaccount.accountid`,
            )
            .pipe(
                map((data) => {
                    let usePostPost = false;
                    data.Data.forEach((item) => {
                        if (item.UsePostPost === true) {
                            usePostPost = true;
                        }
                    });
                    return usePostPost || data.Data.length > 0;
                }),
            );
    }

    public getSaldoInfo(accountID: number) {
        const reducer = (accumulator, currentValue) => accumulator + (currentValue?.Amount || 0);
        let accountNumber = null;
        return this.Get(accountID).pipe(
            tap((account) => (accountNumber = account.AccountNumber)),
            switchMap(() => this.getAccountJournalEntryLines(accountID)),
            map((data) => data.Data),
            map((data: any[]) => {
                const saldo = data.reduce(reducer, 0);
                return {
                    AccountNumber: accountNumber,
                    Saldo: saldo,
                };
            }),
        );
    }

    public getAccountJournalEntryLines(accountID) {
        return this.statisticsService.GetAll(
            `select=Amount,Account.AccountNumber as AccountNumber` +
                `&expand=Account&model=JournalEntryLine&filter=AccountID eq ${accountID}`,
        );
    }

    public getTaxMandetoryInfoLink() {
        return theme.supportArticleUrls?.taxMandatoryInfo || 'https://hjelp.unimicro.no/mvaregistrering';
    }

    getSearchConfigUniForm(valueField = 'ID', includeLedgerAccounts = false) {
        return {
            valueProperty: valueField,
            debounceTime: 200,
            template: (account) => account && `${account.AccountNumber} - ${account.AccountName}`,
            search: (searchText: string) => this.accountSearch(searchText, includeLedgerAccounts),
            getDefaultData: (modelValue) => {
                if (modelValue) {
                    const odata =
                        `model=Account&top=1` +
                        `&select=ID as ID,AccountNumber as AccountNumber,AccountName as AccountName,AccountID as AccountID` +
                        `&filter=${valueField} eq ${modelValue}`;

                    return this.cachedSearch(odata);
                } else {
                    return of([]);
                }
            },
        };
    }

    getAutocompleteOptions(includeLedgerAccounts: boolean): AutocompleteOptions {
        return {
            lookup: (searchText) => this.accountSearch(searchText, includeLedgerAccounts),
            displayFunction: (item) => `${item.AccountNumber} - ${item.AccountName}`,
        };
    }

    accountSearch(searchText: string, includeLedgerAccounts: boolean) {
        const selects = [
            'ID as ID',
            'AccountNumber as AccountNumber',
            'AccountName as AccountName',
            'AccountID as AccountID',
        ];

        const isNumeric = parseInt(searchText, 10);
        let filter =
            (includeLedgerAccounts
                ? `( (Visible eq 'true' and isnull(Account.customerid,0) eq 0 and isnull(Account.supplierid,0) eq 0)`
                : `( (Visible eq 'true' and isnull(Account.AccountID,0) eq 0 and isnull(Account.customerid,0) eq 0 and isnull(Account.supplierid,0) eq 0)`) +
            ` or (Customer.Statuscode ne ${StatusCode.InActive} and Customer.Statuscode ne ${StatusCode.Deleted})` +
            ` or (Supplier.Statuscode ne ${StatusCode.InActive} and Supplier.Statuscode ne ${StatusCode.Error} and Supplier.Statuscode ne ${StatusCode.Deleted}) )`;
        if (searchText?.length) {
            if (isNumeric > 0) {
                filter = `startswith(AccountNumber,'${searchText}') and ${filter}`;
            } else {
                filter = `( contains(Keywords,'${searchText}') or contains(Description,'${searchText}') or contains(AccountName,'${searchText}') ) and ${filter}`;
            }
        }

        const odata = `model=Account&filter=${filter}&select=${selects.join(',')}&expand=Customer,Supplier&orderby=AccountNumber&top=25`;

        return this.cachedSearch(odata).pipe(
            map((accounts) => {
                // Put exact account number match on top of result list
                if (accounts?.length > 1 && searchText?.length === 4 && !isNaN(parseInt(searchText))) {
                    const exactMatchIndex = accounts.findIndex((acc) => acc.AccountNumber === parseInt(searchText));
                    if (exactMatchIndex > 0) {
                        accounts.splice(0, 0, accounts.splice(exactMatchIndex, 1)[0]);
                    }
                }
                return accounts;
            }),
        );
    }

    generalLedgerAccountSearch(searchText: string, showHiddenAccounts: boolean) {
        let orderby = 'AccountNumber';
        let filter = `isnull(Locked,'false') ne 'true' and isnull(SupplierID, 0) eq 0 and isnull(CustomerID, 0) eq 0`;

        if (!showHiddenAccounts) {
            filter += ` and Visible eq 'true'`;
        }

        if (searchText) {
            let searchFilter = !!parseInt(searchText, 10)
                ? `startswith(AccountNumber,'${searchText}')`
                : `contains(AccountName,'${searchText}') or contains(keywords,'${searchText}')`;

            filter += ` and ( ${searchFilter} )`;
        }

        const select = [
            'ID as ID',
            'AccountNumber as AccountNumber',
            'AccountName as AccountName',
            'Visible as Visible',
            'Keywords as Keywords',
            'Description as Description',
            'VatTypeID as VatTypeID',
            'MainGroup.GroupNumber as GroupNumber',
            'MainGroup.Name as GroupName',
            'LockManualPosts as LockManualPosts',
            'UseVatDeductionGroupID as UseVatDeductionGroupID',
        ].join(',');

        const odata = `model=Account&select=${select}&expand=AccountGroup.MainGroup&filter=${filter}&orderby=${orderby}&distinct=false`;
        return this.cachedSearch(odata);
    }

    cachedSearch(odata: string) {
        const key = super.hashFnv32a(odata).toString();
        let request: Observable<any[]> = this.accountSearchCache.get(key);
        if (!request) {
            request = this.statisticsService.GetAllUnwrapped(odata).pipe(
                catchError((err) => {
                    console.error(err);
                    return of([]);
                }),
                publishReplay(1),
                refCount(),
            );

            this.accountSearchCache.add(key, request);
        }

        return request;
    }
}
