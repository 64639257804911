<section class="toolbar-wrapper">
    <!-- TOOLBAR LEFT -->
    <section class="toolbar-left">
        <section class="title-section">
            <uni-breadcrumbs
                *ngIf="!config?.hideBreadcrumbs"
                [breadcrumbs]="config?.breadcrumbs"
                [omitFinalCrumb]="config?.omitFinalCrumb"
            >
            </uni-breadcrumbs>

            <section class="toolbar-header">
                <uni-toolbar-search
                    *ngIf="searchVisible; else textHeader"
                    [config]="searchConfig"
                    (clickOutside)="toggleSearch()"
                    (close)="searchVisible = false"
                >
                </uni-toolbar-search>

                <ng-template #textHeader>
                    <h1 class="toolbar-title" (click)="toggleSearch()">
                        {{ config?.title || searchConfig?.initValue | translate }}
                        <i class="material-icons" *ngIf="searchConfig"> search </i>

                        <i
                            *ngIf="config?.showHelpSectionToggle"
                            (click.stopPropagation)="toggleHelpSection.emit()"
                            class="material-icons help-section-toggle"
                            role="button"
                            aria-label="Toggle help section"
                        >
                            info
                        </i>
                    </h1>
                </ng-template>
            </section>
        </section>

        <section class="main-section">
            <info-banner *ngIf="config?.infoBannerConfig" [config]="config?.infoBannerConfig"> </info-banner>

            <uni-statustrack
                *ngIf="statustrack || config?.statustrack"
                [config]="statustrack || config.statustrack"
                [entityType]="config?.entityType"
                [entityID]="config?.entityID"
                [showFullStatustrack]="showFullStatustrack"
                (statusSelectEvent)="selectStatus($event)"
            >
            </uni-statustrack>

            <uni-toolbar-validation *ngIf="validationMessages?.length" [validationMessages]="validationMessages">
            </uni-toolbar-validation>

            <section class="toolbar-extras" #toolbarExtras>
                <section *ngIf="selectConfig" class="toolbar_uniselect">
                    {{ selectConfig.label }}
                    <uni-select
                        [config]="uniSelectConfig"
                        [items]="selectConfig.items"
                        [value]="selectConfig.selectedItem"
                        (valueChange)="selectValueSelected($event)"
                    >
                    </uni-select>
                </section>

                <ng-template ngFor let-subhead [ngForOf]="subheads || config?.subheads">
                    <small
                        class="toolbar-subhead"
                        *ngIf="subhead.title || subhead.link"
                        [title]="subhead.title"
                        [ngClass]="subhead.classname || ''"
                        (click)="triggerSubheadEvent(subhead)"
                        [uniTooltip]="subhead.tooltip"
                    >
                        <i class="material-icons" *ngIf="subhead.icon"> {{ subhead.icon }} </i>
                        <span *ngIf="subhead.label" class="subhead-label">{{ subhead.label | translate }}: </span>

                        <a
                            *ngIf="subhead.link"
                            [href]="subhead.link"
                            [target]="subhead.externalLink ? '_blank' : '_self'"
                        >
                            {{ subhead.title | translate }}
                        </a>

                        <ng-container *ngIf="!subhead.link">
                            {{ subhead.title | translate }}
                        </ng-container>
                    </small>
                </ng-template>

                <uni-tags
                    *ngIf="tags"
                    [tags]="tags"
                    [config]="tagConfig"
                    (tagsChange)="tagsChangeEvent($event)"
                    (tagsBusy)="tagsBusyEvent($event)"
                >
                </uni-tags>
            </section>
            <ng-content></ng-content>
        </section>
    </section>

    <!-- TOOLBAR RIGHT -->
    <context-support [wizard]="config?.helpWizard"></context-support>

    <section #toolbarRight class="toolbar-right">
        <button
            *ngIf="config?.navigation?.prev"
            title="Forrige"
            class="toolbar-button icon-button prev"
            type="button"
            (click)="navigate('prev')"
        >
            <i class="material-icons">arrow_back</i>
        </button>

        <button
            *ngIf="config?.navigation?.next"
            title="Neste"
            class="toolbar-button icon-button next"
            type="button"
            (click)="navigate('next')"
        >
            <i class="material-icons">arrow_forward</i>
        </button>

        <uni-comments
            *ngIf="canShowChat && commentsConfig?.entityID > 0"
            [entity]="commentsConfig.entityType"
            [entityID]="commentsConfig.entityID"
            [toggleMode]="true"
        >
        </uni-comments>

        <button
            *ngIf="config?.navigation?.add"
            [title]="config.navigation.add.label || 'Opprett ny'"
            class="toolbar-button new-button"
            type="button"
            (click)="onCreateNewClick()"
        >
            <i class="material-icons">add</i>
            <span>{{ config.navigation.add.label || 'Opprett ny' }}</span>
        </button>

        <uni-context-menu
            *ngIf="contextmenu || config?.contextmenu?.length"
            [actions]="contextmenu || config.contextmenu"
            [hideDisabled]="config?.hideDisabledActions"
        >
        </uni-context-menu>

        <button
            *ngFor="let button of config?.buttons"
            class="toolbar-button"
            [ngClass]="button.class"
            (click)="customButtonClick(button)"
            [attr.aria-busy]="button['_busy']"
            [matTooltip]="button.tooltip"
            [disabled]="button.disabled || false"
        >
            <i *ngIf="button.icon" [ngClass]="button.iconClass || 'material-icons'">{{ button.icon }}</i>
            <span *ngIf="button.label">{{ button.label | translate }}</span>
        </button>

        <ng-content select="[toolbarButtonArea]"></ng-content>

        <ng-container *ngIf="dropdownButton">
            <button #toggleBtn [ngClass]="dropdownButton.class || 'good'">
                {{ dropdownButton.label }}
                <i class="material-icons" style="margin: 0 -4px 0 0">expand_more</i>
            </button>

            <dropdown-menu [trigger]="toggleBtn">
                <ng-template>
                    <section
                        class="dropdown-menu-item"
                        *ngFor="let item of dropdownButton.items"
                        (click)="item.action()"
                        [attr.aria-disabled]="item.disabled ? true : null"
                    >
                        {{ item.label | translate }}
                    </section>
                </ng-template>
            </dropdown-menu>
        </ng-container>

        <uni-save
            id="toolbar-save"
            *ngIf="saveactions || config?.saveactions"
            [actions]="saveactions || config?.saveactions"
            [hideDisabled]="config?.hideDisabledActions"
        >
        </uni-save>
    </section>
</section>

@if (cms?.banner | async; as banner) {
    <custom-cms-banner [banner]="banner" class="sub-toolbar-banner" />
}
