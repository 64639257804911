import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '../../../../framework/uni-modal/interfaces';
import { File } from '../../../unientities';
import { UniImage } from '../../../../framework/uniImage/uniImage';
import { FileService } from '@app/services/common/fileService';

export interface IUpdatedFileListEvent {
    entity: string;
    entityID: number;
    files: File[];
}

@Component({
    selector: 'image-modal',
    template: `
        <section role="dialog" class="uni-modal medium">
            <header>Forhåndsvisning</header>
            <article class="image-modal-body">
                <uni-image
                    *ngIf="options?.data"
                    [singleImage]="singleImage"
                    [fileIDs]="options.data.fileIDs || []"
                    [entity]="options.data.entity"
                    [entityID]="options.data.entityID"
                    [showFileID]="options.data.showFileID || null"
                    [filterOnStatusCode]="options?.data?.filterOnStatusCode"
                    [readonly]="options.data.readonly"
                    [canUseInbox]="useInbox"
                    [accept]="accept"
                    (imageUnlinked)="onFileUnlinked($event)"
                    (fileListReady)="fileListReady($event)"
                ></uni-image>
            </article>
            <footer>
                <button class="secondary" (click)="close()">Lukk</button>
            </footer>
        </section>
    `,
})
export class ImageModal implements IUniModal {
    @ViewChild(UniImage) uniImage: UniImage;

    @Input() options: IModalOptions;
    @Output() onClose = new EventEmitter<IUpdatedFileListEvent>(false);

    constructor(private fileService: FileService) {}

    private files: any;
    singleImage: boolean = true;
    useInbox: boolean;
    accept: string;

    ngOnInit() {
        // Check specifically for false because truthy/falsy..
        if (this.options.data.singleImage === false) {
            this.singleImage = false;
        }

        this.useInbox = this.options.data.useInbox ?? false;
        this.accept = this.options.data.accept ?? '';
    }

    refreshImages() {
        this.uniImage.refreshFiles();
    }

    fileListReady(files: File[]) {
        this.files = files;
        this.options.cancelValue = {
            entity: this.options.data.entity,
            entityID: this.options.data.entityID,
            files: this.files,
        };
    }

    close() {
        this.onClose.emit({
            entity: this.options.data.entity,
            entityID: this.options.data.entityID,
            files: this.files,
        });
    }

    onFileUnlinked(file: File) {
        this.fileService.tagFileNotInUse(file.ID).subscribe();
    }
}
