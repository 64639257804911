import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { AuthService, CurrentUserWithout2FADetails } from '@app/authService';
import { INavbarLink, INavbarLinkSection, SETTINGS_LINKS } from './navbar-links-common';
import { UniModules } from './tabstrip/tabService';
import { UniHttp } from '@uni-framework/core/http/http';
import { theme, THEMES } from 'src/themes/theme';
import { FeaturePermissionService } from '@app/featurePermissionService';
import { NAVBAR_LINKS } from './navbar-links';
import { DimensionSettingsService } from '@app/services/common/dimensionSettingsService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';
import { ElsaProductService } from '@app/services/elsa/elsaProductService';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { ElsaProductStatusCode } from '@app/models';

@Injectable()
export class NavbarLinkService {
    private defaultNavbarLinks: INavbarLinkSection[];

    private user: CurrentUserWithout2FADetails;
    public localeValue: string = '';
    public linkSections$ = new BehaviorSubject<INavbarLinkSection[]>([]);
    public settingsSection$ = new BehaviorSubject<INavbarLinkSection[]>([]);

    megaMenuVisible$ = new BehaviorSubject(false);

    public dimensions: any[];
    public company: any = {};

    constructor(
        private authService: AuthService,
        private featurePermissionService: FeaturePermissionService,
        private dimensionSettingsService: DimensionSettingsService,
        private http: UniHttp,
        private httpClient: HttpClient,
        private statisticsService: StatisticsService,
        private elsaPurchaseService: ElsaPurchaseService,
        private elsaProductService: ElsaProductService,
        private financialYearService: FinancialYearService,
    ) {
        this.getNavbarLinks().subscribe((res) => {
            this.defaultNavbarLinks = res;
            combineLatest([
                this.authService.authentication$,
                this.financialYearService.lastSelectedFinancialYear$,
            ]).subscribe({
                next: ([authDetails, _]) => {
                    this.company = authDetails.activeCompany;
                    if (authDetails.user) {
                        this.user = authDetails.user;
                        this.linkSections$.next(this.getLinksFilteredByPermissions(this.user));
                        this.refreshLinks();
                    }
                },
            });
        });
    }

    private getNavbarLinks(): Observable<INavbarLinkSection[]> {
        if (theme.theme === THEMES.UE) {
            return of(NAVBAR_LINKS);
        }

        return this.httpClient
            .get<INavbarLinkSection[]>(`config/dist/theme/navbar-links.json?v=${window['VERSION_GUID']}`)
            .pipe(
                take(1),
                catchError(() => of(NAVBAR_LINKS)),
            );
    }

    public async refreshLinks() {
        this.settingsSection$.next(this.getSettingsFilteredByPermissions(this.user));
        if (theme.theme === THEMES.SR) {
            this.linkSections$.next(this.getLinksFilteredByPermissions(this.user));
        } else {
            let linkSections = await this.checkbankAccess(this.getLinksFilteredByPermissions(this.user));
            linkSections = await this.checkAnnualSettlement(linkSections);

            if (this.featurePermissionService.canShowRoute('ui_dimensions')) {
                this.getDimensionRouteSection(this.user).subscribe(
                    (dimensionLinks) => {
                        if (dimensionLinks) {
                            const dimensionsIdx = linkSections.findIndex(
                                (section) => section.name === 'NAVBAR.DIMENSION',
                            );

                            // Insert before marketplace
                            const insertIndex = linkSections.findIndex(
                                (section) => section.name === 'NAVBAR.MARKETPLACE',
                            );

                            // If dimensions is already in the list, just update
                            if (dimensionsIdx !== -1) {
                                linkSections[dimensionsIdx] = dimensionLinks;
                            } else if (insertIndex > 0) {
                                linkSections.splice(insertIndex, 0, dimensionLinks);
                            } else {
                                linkSections.push(dimensionLinks);
                            }
                        }

                        this.linkSections$.next(linkSections);
                    },
                    () => this.linkSections$.next(linkSections),
                );
            } else {
                this.linkSections$.next(linkSections);
            }
        }
    }

    public getSettingsFilteredByPermissions(user: CurrentUserWithout2FADetails) {
        const settingsSections: INavbarLinkSection[] = cloneDeep(SETTINGS_LINKS);
        // Filter out links the user doesnt have access to for every section
        settingsSections.forEach((section) => {
            section.linkGroups = section.linkGroups.map((group) => {
                group.links = group.links.filter((link) => {
                    const canActivate = this.authService.canActivateRoute(user, link.url);
                    const hasFeaturePermission =
                        !link.featurePermission ||
                        this.featurePermissionService.canShowUiFeature(link.featurePermission);

                    return canActivate && hasFeaturePermission;
                });

                group.links = group.links.map((link) => {
                    link.subSettings = link.subSettings?.filter((settingsLink) => {
                        return (
                            !settingsLink.featurePermission ||
                            this.featurePermissionService.canShowUiFeature(settingsLink.featurePermission)
                        );
                    });

                    return link;
                });

                return group;
            });
        });

        // Filter out sections where the user doesnt have access to any links
        return settingsSections.filter((section) => {
            return section.linkGroups.some((group) => group.links.length > 0);
        });
    }

    private getLinksFilteredByPermissions(user): any[] {
        const fromLocalStorage = this.company.Key
            ? localStorage.getItem(`SIDEBAR_${this.localeValue}_${this.company.Key}`)
            : null;
        const routeSections: INavbarLinkSection[] = cloneDeep(this.defaultNavbarLinks);

        if (fromLocalStorage) {
            const valuesFromLS: any[] = JSON.parse(fromLocalStorage);
            routeSections.forEach((section) => {
                section.linkGroups = section.linkGroups.map((group) => {
                    group.links = group.links.filter((link) => {
                        const canActivate = this.authService.canActivateRoute(user, link.url);
                        const hasFeaturePermission =
                            !link.featurePermission ||
                            this.featurePermissionService.canShowUiFeature(link.featurePermission);

                        // Use saved config from localStorage
                        const savedState = valuesFromLS.find((value) => value.name === link.name);
                        link.activeInSidebar = !!savedState ? savedState.activeInSidebar : link.activeInSidebar;

                        return canActivate && hasFeaturePermission;
                    });
                    return group;
                });
            });
        }

        /*
            Until the feature is stable we only want to show the navbar link
            for customer-ledger in sk.unieconomy.no, and a few selected companies in UE.

            This is a temporary solution, and should be removed in a sprint or two.

            https://unimicro.atlassian.net/browse/KT-33
            https://unimicro.atlassian.net/browse/KT-34
        */
        this.checkCustomerLedgerLink(routeSections);
        this.hideMarketplaceAgenciesForBureauCustomers(routeSections);

        // Filter out links the user doesnt have access to for every section
        routeSections.forEach((section) => {
            section.linkGroups = section.linkGroups.map((group) => {
                group.links = group.links.filter((link) => {
                    const canActivate = this.authService.canActivateRoute(user, link.url);
                    const hasFeaturePermission =
                        !link.featurePermission ||
                        this.featurePermissionService.canShowUiFeature(link.featurePermission);
                    return canActivate && hasFeaturePermission;
                });

                return group;
            });
        });

        // Filter out sections where the user doesnt have access to any links
        // AnnualSettlement needs it's own handling since it always has at least one accessable link
        return routeSections.filter((section) => {
            const minimumLinksCount = section.name === 'NAVBAR.ANNUAL_SETTLEMENT' ? 1 : 0;
            return (
                section.isOnlyLinkSection || section.linkGroups.some((group) => group.links.length > minimumLinksCount)
            );
        });
    }

    // Hopefully temporary check to see if EXT02 users has autobank, and if not hide bank links in sidebar
    // I really dont see the value with this, they can just add them..
    checkbankAccess(linkSection: INavbarLinkSection[]): Promise<INavbarLinkSection[]> {
        const hasLocalStored = this.company.Key
            ? !!localStorage.getItem(`SIDEBAR_${this.localeValue}_${this.company.Key}`)
            : false;
        if (theme.theme !== THEMES.EXT02 || hasLocalStored) {
            return of(linkSection).toPromise();
        } else {
            return new Promise((resolve, reject) => {
                this.statisticsService
                    .GetAllUnwrapped('model=CompanySettings&select=HasAutobank as HasAutobank')
                    .subscribe(
                        (cs) => {
                            const hasAutobank = cs[0]['HasAutobank'];
                            if (!hasAutobank) {
                                const section = linkSection.find((s) => s.name === 'NAVBAR.BANK');
                                if (section?.linkGroups[0]?.links) {
                                    section.linkGroups[0].links = section.linkGroups[0].links.map((link) => {
                                        if (link.name !== 'NAVBAR.BANK_RECONCILIATION') {
                                            link.activeInSidebar = false;
                                        }
                                        return link;
                                    });
                                }
                            }

                            resolve(linkSection);
                        },
                        (err) => resolve(linkSection),
                    );
            });
        }
    }

    // For marketing purposes AnnualSettlement will have two variants of menu
    // We have a second product called Forhaandsfastsetting which is an identical product to AnnualSettlement
    // only with a different name and cannot be activated simultaneous on a given financial year
    // In these case we need to either activate links for AnnualSettlement or Forhaandsfastsetting
    checkAnnualSettlement(linkSection: INavbarLinkSection[]): Promise<INavbarLinkSection[]> {
        const hasLocalStored = this.company.Key
            ? !!localStorage.getItem(`SIDEBAR_${this.localeValue}_${this.company.Key}`)
            : false;
        if (hasLocalStored) {
            return of(linkSection).toPromise();
        } else {
            return new Promise((resolve, reject) => {
                const contractType = this.authService.currentUser.License?.ContractType?.TypeID;
                const productNameAnnualSettlement = `AnnualSettlement${this.financialYearService.getActiveYear()}`;
                const productNameForhaandsfastsetting = `Forhaandsfastsetting${this.financialYearService.getActiveYear()}`;
                forkJoin([
                    this.elsaPurchaseService.getPurchaseByProductName(`${productNameAnnualSettlement}`),
                    this.elsaPurchaseService.getPurchaseByProductName(`${productNameForhaandsfastsetting}`),
                    this.elsaProductService.getProductsOnContractTypes(
                        contractType,
                        `name eq '${productNameAnnualSettlement}'`,
                        'id,productstatus',
                    ),
                    this.elsaProductService.getProductsOnContractTypes(
                        contractType,
                        `name eq '${productNameForhaandsfastsetting}'`,
                        'id,productstatus',
                    ),
                ]).subscribe({
                    next: ([
                        purchaseAnnualSettlement,
                        purchaseForhaandsfastsetting,
                        productsAnnualSettlement,
                        productsForhaandsfastsetting,
                    ]) => {
                        const hasPurchasedAnnualSettlement = !!purchaseAnnualSettlement;
                        const productExistsAnnualSettlement =
                            productsAnnualSettlement[0]?.ProductStatus === ElsaProductStatusCode.Live;
                        const productActiveAnnualSettlement =
                            hasPurchasedAnnualSettlement && productExistsAnnualSettlement;

                        const hasPurchasedForhaandsfastsetting = !!purchaseForhaandsfastsetting;
                        const productExistsForhaandsfastsetting =
                            productsForhaandsfastsetting[0]?.ProductStatus === ElsaProductStatusCode.Live;
                        const productActiveForhaandsfastsetting =
                            hasPurchasedForhaandsfastsetting && productExistsForhaandsfastsetting;

                        // DNB will have it's own menu option for both of these products
                        if (theme.theme === THEMES.EXT02) {
                            const sectionIndex = linkSection.findIndex((s) => s.name === 'NAVBAR.ACCOUNTING');
                            const linkIndex = linkSection[sectionIndex].linkGroups[0].links.findIndex(
                                (g) => g.name === 'NAVBAR.ANNUAL_SETTLEMENT',
                            );

                            if (sectionIndex === -1 || linkIndex === -1) {
                                resolve(linkSection);
                            }

                            // Only change the name of the link if Forhaandsfastsetting is active and AnnualSettlement not
                            if (!productActiveAnnualSettlement && productActiveForhaandsfastsetting) {
                                linkSection[sectionIndex].linkGroups[0].links[linkIndex] = {
                                    name: 'NAVBAR.FORHAANDSFASTSETTING',
                                    url: '/annual-settlement',
                                    activeInSidebar: true,
                                };
                            }
                        } else {
                            const sectionIndex = linkSection.findIndex((s) => s.name === 'NAVBAR.ANNUAL_SETTLEMENT');

                            if (sectionIndex === -1) {
                                resolve(linkSection);
                            }

                            // If AnnualSettlement product is not active initialize default link for AnnualSettlement
                            if (!productActiveAnnualSettlement && !productActiveForhaandsfastsetting) {
                                linkSection[sectionIndex] = {
                                    name: 'NAVBAR.ANNUAL_SETTLEMENT',
                                    url: '/annual-settlement/get-started',
                                    icon: 'event_available',
                                    megaMenuGroupIndex: 0,
                                    linkGroups: [],
                                    isOnlyLinkSection: true,
                                };
                                // Only change the name of the link if Forhaandsfastsetting is active and AnnualSettlement not
                            } else if (
                                !productActiveAnnualSettlement &&
                                productActiveForhaandsfastsetting &&
                                sectionIndex >= 0
                            ) {
                                linkSection[sectionIndex].name = 'NAVBAR.FORHAANDSFASTSETTING';
                            }
                        }

                        resolve(linkSection);
                    },
                    error: (err) => resolve(linkSection),
                });
            });
        }
    }

    private getDimensionRouteSection(user): Observable<INavbarLinkSection> {
        // Add custom dimensions to the list
        if (this.authService.canActivateRoute(user, 'dimensions/customdimensionlist')) {
            return this.dimensionSettingsService.GetAll(null).pipe(
                map((dimensions) => {
                    return {
                        name: 'NAVBAR.DIMENSION',
                        url: '/dimensions',
                        icon: 'developer_board',
                        isSuperSearchComponent: true,
                        megaMenuGroupIndex: 0,
                        linkGroups: [
                            {
                                name: '',
                                links: this.getDimensionLinks(dimensions),
                            },
                        ],
                    };
                }),
            );
        } else {
            return of(null);
        }
    }

    public resetToDefaultMenuStructure() {
        localStorage.removeItem(`SIDEBAR_${this.localeValue}_${this.company.Key}`);
        this.refreshLinks();
    }

    public getQuery(url: string) {
        return this.http
            .asGET()
            .usingStatisticsDomain()
            .withEndPoint(url)
            .send()
            .pipe(map((res) => res.body));
    }

    public getDimensionLinks(dimensions) {
        const links: any = [
            {
                name: 'Prosjekt',
                url: '/dimensions/overview/1',
                moduleID: UniModules.Dimensions,
                activeInSidebar: true,
            },
            {
                name: 'Avdeling',
                url: '/dimensions/overview/2',
                moduleID: UniModules.Dimensions,
                activeInSidebar: true,
            },
        ];

        dimensions.forEach((dim) => {
            // add check to see if dim.IsActive??
            links.push({
                name: dim.Label,
                url: '/dimensions/overview/' + dim.Dimension,
                moduleID: UniModules.Dimensions,
                activeInSidebar: true,
                isSuperSearchComponent: false,
                moduleName: 'Dimension' + dim.Dimension,
                selects: [
                    { key: 'ID', isNumeric: true },
                    { key: 'Number', isNumeric: false },
                    { key: 'Name', isNumeric: false },
                ],
                // shortcutName: 'Ny ' + dim.Label,
                prefix: 'dim' + dim.Dimension,
            });
        });

        try {
            const savedLinks: INavbarLink[] = JSON.parse(
                localStorage.getItem(`SIDEBAR_${this.localeValue}_${this.company.Key}`),
            );

            links.forEach((link) => {
                const savedState = savedLinks.find((l) => l.name === link.name);
                link.activeInSidebar = savedState?.activeInSidebar ?? true;
            });
        } catch (e) {}

        return links;
    }

    public saveSidebarLinks(linkSection: INavbarLinkSection[]) {
        const nameAndActiveValue = [];

        linkSection.forEach((section) => {
            section.linkGroups.forEach((group) => {
                group.links.map((link) => {
                    nameAndActiveValue.push({
                        activeInSidebar: link.activeInSidebar,
                        name: link.name,
                    });
                });
            });
        });

        localStorage.setItem(`SIDEBAR_${this.localeValue}_${this.company.Key}`, JSON.stringify(nameAndActiveValue));
        this.refreshLinks();
    }

    private checkCustomerLedgerLink(routeSections: INavbarLinkSection[]) {
        let shouldBeVisible = true;
        if (window.location.host === 'unieconomy.no') {
            shouldBeVisible = [
                'd4892331-3ee6-4fa1-8dcb-5e0db068115c',
                'f452d023-a5fd-4dbb-a23f-7df6e3ca3bbd',
                '9333a391-c6ab-4b8f-98da-2eb75b9554d4',
                'b1a26db0-1710-444b-9a34-008bac254b20',
            ].includes(this.authService.activeCompany.Key);
        }

        if (!shouldBeVisible) {
            const accountingSection = routeSections.find((section) => section.name === 'NAVBAR.ACCOUNTING');
            if (accountingSection) {
                const index = accountingSection.linkGroups[0].links.findIndex(
                    (link) => link.name === 'NAVBAR.CUSTOMER_LEDGER',
                );
                if (index >= 0) {
                    accountingSection.linkGroups[0].links.splice(index, 1);
                }
            }
        }
    }

    private hideMarketplaceAgenciesForBureauCustomers(routeSections: INavbarLinkSection[]) {
        if (theme.theme !== THEMES.UE && theme.theme !== THEMES.SR) return;

        if (this.authService.currentUser?.License?.CustomerInfo?.CustomerType === 5) {
            const marketplaceSection = routeSections.find((section) => section.name === 'NAVBAR.MARKETPLACE');

            if (marketplaceSection) {
                const index = marketplaceSection.linkGroups[0].links.findIndex(
                    (link) => link.name === 'NAVBAR.AGENCIES',
                );
                if (index >= 0) {
                    marketplaceSection.linkGroups[0].links.splice(index, 1);
                }
            }
        }
    }
}
